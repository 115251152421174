import React, { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "./decboofive.css";

// Import Swiper styles
import "swiper/css";

import "swiper/css/navigation";

// import required modules
import { Navigation } from "swiper";

import $ from "jquery";

const style1 = { display: "none" };

const imageZoom = async () => {
  $(".fa-angle-left").on("click", function () {
    $(".swiper-button-prev").click();
  });

  $(".fa-angle-right").on("click", function () {
    $(".swiper-button-next").click();
  });

  $(".swiper-button-next, .swiper-button-prev").attr("style", "display:none");

  $("body").keyup(function (e) {
    var code = e.keyCode || e.which; //Find the key pressed

    if (code == 39) {
      $(".fa-angle-right").click();
    }
    if (code == 37) {
      $(".fa-angle-left").click();
    }
  });

  $(".dbl-sub-sec").on("mouseenter", function () {
    $(this).find(".decor-tag-link").fadeIn();
  });

  $(".dbl-sub-sec").on("mouseleave", function () {
    $(this).find(".decor-tag-link").fadeOut();
  });

  /*document.getElementById('produto').style.display="none";*/
};

const Homediaries = () => {
  useEffect(() => {
    imageZoom();

    if ($(window).width() < 768) {
      // $('.image-mobile').find('img').removeClass('hero-banner-desktop');
      $(".image-mobile-1").find("img").addClass("img-book-mobile");
      $(".image-mobile-1").find("img").attr("src", "/images/2024/main-2-mobile.png");

      $(".image-mobile-2").find("img").addClass("img-book-mobile");
      $(".image-mobile-2").find("img").attr("src", "/images/2024/main-3-mobile.png");
    }
  }, []);

  return (
    <Swiper
      slidesPerView={1}
      spaceBetween={20}
      modules={[Navigation]}
      navigation={true}
      className="mySwiper nexthd prvhd"
    >
      <SwiperSlide className="d-flex align-items-center justify-content-center mt-4">
        <center>
          <div className="container-fluid dbl-2 mt-3 mb-3">
            <div className="row padding-slides">
              <div className="col-12 col-md-12 pl-2 pr-2">
                <div className="h-100">
                  <img
                    className="mt-1 heightimg"
                    src="/images/2024/main-1.webp"
                  />
                </div>
              </div>
            </div>
          </div>
        </center>
      </SwiperSlide>

      <SwiperSlide className="d-flex align-items-center justify-content-center mt-4">
        <div className="container dbl-2 mt-3 mb-3">
          <div className="row padding-slides">
            <div className="col-12 col-md-12 pl-2 pr-2">
              <center>
                <div className="h-100 image-mobile-1">
                  <img className="w-100 h-100 " src="/images/2024/main-2.webp"></img>
                  <div className="decor-tag-link" style={{ display: "none" }}>
                    <div class="decor-tag">
                      <span class="dt-icon float-left">
                        <img src="/images/magnifying-glass-plus.png"></img>
                      </span>
                      <span class="dt-text float-right">View Decor</span>
                    </div>
                  </div>
                </div>
              </center>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide className="d-flex align-items-center justify-content-center mt-4">
        <div className="container dbl-2 mt-3 mb-3">
          <div className="row padding-slides">
            <div className="col-12 col-md-12 pl-2 pr-2">
              <center>
                <div className="h-100 image-mobile-2">
                  <img className="w-100 h-100 " src="/images/2024/main-3.webp"></img>
                  <div className="decor-tag-link" style={{ display: "none" }}>
                    <div class="decor-tag">
                      <span class="dt-icon float-left">
                        <img src="/images/magnifying-glass-plus.png"></img>
                      </span>
                      <span class="dt-text float-right">View Decor</span>
                    </div>
                  </div>
                </div>
              </center>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div className="container dbl-2 mt-3 mb-3">
          <div className="row padding-slides">
            <p className="m-0">Decor Code: S020 Golden Vegas</p>
            <div className="col-sm-12 col-md-7 pl-2 pr-0">
              <div className="dbl-main-sec h-100 mt-2">
              <video class="w-100 h-100" controls autoPLay muted >
                  <source
                    src="/videos/catalogs/2024/s020.mp4"
                    type="video/mp4"
                  ></source>
                </video>
              </div>
            </div>
            <div className="col-sm-5 col-md-5 pl-2 pr-0">
              <div className="dbl-sub-sec h-100 mt-2">
                <a href="/decorsdetail/s020-golden-vegas" target="_blank">
                  <img
                    className=" w-100 h-100"
                    src="/images/2024/1.webp"
                  ></img>
                  <div className="decor-tag-link" style={{ display: "none" }}>
                    <div class="decor-tag">
                      <span class="dt-icon float-left">
                        <a href="/decorsdetail/s020-golden-vegas" target="_blank">
                          <img src="/images/magnifying-glass-plus.png"></img>
                        </a>
                      </span>
                      <span class="dt-text float-right">View Decor</span>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div className="container dbl-2 mt-3 mb-3">
          <div className="row padding-slides">
            <p className="m-0">Decor Code: S019 Fuji </p>
            <div className="col-sm-12 col-md-7 pl-2 pr-0">
              <div className="dbl-main-sec h-100 mt-2">
                <video class="w-100 h-100" controls autoPLay muted>
                  <source
                    src="/videos/catalogs/2024/s019.mp4"
                    type="video/mp4"
                  ></source>
                </video>
              </div>
            </div>
            <div className="col-sm-5 col-md-5 pl-2 pr-0">
              <div className="dbl-sub-sec h-100 mt-2">
                <a href="/decorsdetail/s019-fuji" target="_blank">
                  <img
                    className=" w-100 h-100"
                    src="/images/2024/2.webp"
                  ></img>
                  <div className="decor-tag-link" style={{ display: "none" }}>
                    <div class="decor-tag">
                      <span class="dt-icon float-left">
                        <a
                          href="/decorsdetail/s019-fuji"
                          target="_blank"
                        >
                          <img src="/images/magnifying-glass-plus.png"></img>
                        </a>
                      </span>
                      <span class="dt-text float-right">View Decor</span>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div className="container dbl-2 mt-3 mb-3">
          <div className="row padding-slides">
            <p className="m-0">Decor Code: S016 Valongo Silver</p>

            <div className="col-sm-12 col-md-7 pl-2 pr-0">
              <div className="dbl-main-sec h-100 mt-2">
                <a href="/decorsdetail/s016-valongo-silver" target="_blank">
                  <img
                    className="lb-btn mt-1 mb-2 w-100 h-100"
                    src="/images/2024/19.webp"
                  ></img>
                  <div className="decor-tag-link" style={{ display: "none" }}>
                    <div class="decor-tag">
                      <span class="dt-icon float-left">
                        <a
                          href="/decorsdetail/s016-valongo-silver"
                          target="_blank"
                        >
                          <img src="/images/magnifying-glass-plus.png"></img>
                        </a>
                      </span>
                      <span class="dt-text float-right">View Decor</span>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-sm-5 col-md-5 pl-2 pr-0">
              <div className="dbl-sub-sec h-100 mt-2">
                <a href="/decorsdetail/s016-valongo-silver" target="_blank">
                  <img
                    className="mt-1 w-100 h-100"
                    src="/images/2024/3.webp"
                  ></img>
                  <div className="decor-tag-link" style={{ display: "none" }}>
                    <div class="decor-tag">
                      <span class="dt-icon float-left">
                        <a
                          href="/decorsdetail/s016-valongo-silver"
                          target="_blank"
                        >
                          <img src="/images/magnifying-glass-plus.png"></img>
                        </a>
                      </span>
                      <span class="dt-text float-right">View Decor</span>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div className="container dbl-2 mt-3 mb-3">
          <div className="row padding-slides">
            <p className="m-0">Decor Code: S017 Valongo Slate</p>

            <div className="col-sm-12 col-md-7 pl-2 pr-0">
              <div className="dbl-main-sec h-100 mt-2">
                <a href="/decorsdetail/s017-valongo-slate" target="_blank">
                  <img
                    className="lb-btn mt-1 mb-2 w-100 h-100"
                    src="/images/2024/18.webp"
                  ></img>
                  <div className="decor-tag-link" style={{ display: "none" }}>
                    <div class="decor-tag">
                      <span class="dt-icon float-left">
                        <a
                          href="/decorsdetail/s017-valongo-slate"
                          target="_blank"
                        >
                          <img src="/images/magnifying-glass-plus.png"></img>
                        </a>
                      </span>
                      <span class="dt-text float-right">View Decor</span>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-sm-5 col-md-5 pl-2 pr-0">
              <div className="dbl-sub-sec h-100 mt-2">
                <a href="/decorsdetail/s017-valongo-slate" target="_blank">
                  <img
                    className="mt-1 w-100 h-100"
                    src="/images/2024/4.webp"
                  ></img>
                  <div className="decor-tag-link" style={{ display: "none" }}>
                    <div class="decor-tag">
                      <span class="dt-icon float-left">
                        <a
                          href="/decorsdetail/s017-valongo-slate"
                          target="_blank"
                        >
                          <img src="/images/magnifying-glass-plus.png"></img>
                        </a>
                      </span>
                      <span class="dt-text float-right">View Decor</span>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div className="container dbl-2 mt-3 mb-3">
          <div className="row padding-slides">
            <p className="m-0">Decor Code: 1211</p>
            <div className="col-sm-12 col-md-7 pl-2 pr-0">
              <div className="dbl-main-sec h-100 mt-2">
                <video class="w-100 h-100" controls autoPLay muted>
                  <source
                    src="/videos/catalogs/2024/1211.mp4"
                    type="video/mp4"
                  ></source>
                </video>
              </div>
            </div>
            <div className="col-sm-5 col-md-5 pl-2 pr-0">
              <div className="dbl-sub-sec h-100 mt-2">
                <a href="/decorsdetail/1211" target="_blank">
                  <img
                    className=" w-100 h-100"
                    src="/images/2024/5.webp"
                  ></img>
                  <div className="decor-tag-link" style={{ display: "none" }}>
                    <div class="decor-tag">
                      <span class="dt-icon float-left">
                        <a
                          href="/decorsdetail/1211"
                          target="_blank"
                        >
                          <img src="/images/magnifying-glass-plus.png"></img>
                        </a>
                      </span>
                      <span class="dt-text float-right">View Decor</span>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div className="container dbl-2 mt-3 mb-3">
          <div className="row padding-slides">
            <p className="m-0">Decor Code: 1212</p>
            <div className="col-sm-12 col-md-7 pl-2 pr-0">
              <div className="dbl-main-sec h-100 mt-2">
                <video class="w-100 h-100" controls autoPLay muted>
                  <source
                    src="/videos/catalogs/2024/1212.mp4"
                    type="video/mp4"
                  ></source>
                </video>
              </div>
            </div>
            <div className="col-sm-5 col-md-5 pl-2 pr-0">
              <div className="dbl-sub-sec h-100 mt-2">
                <a href="/decorsdetail/1212" target="_blank">
                  <img
                    className=" w-100 h-100"
                    src="/images/2024/6.webp"
                  ></img>
                  <div className="decor-tag-link" style={{ display: "none" }}>
                    <div class="decor-tag">
                      <span class="dt-icon float-left">
                        <a
                          href="/decorsdetail/1212"
                          target="_blank"
                        >
                          <img src="/images/magnifying-glass-plus.png"></img>
                        </a>
                      </span>
                      <span class="dt-text float-right">View Decor</span>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>
      
      <SwiperSlide>
        <div className="container dbl-2 mt-3 mb-3">
          <div className="row padding-slides">
            <p className="m-0">Decor Code: 1014 Hercley Oak</p>
            <div className="col-sm-12 col-md-7 pl-2 pr-0">
              <div className="dbl-main-sec h-100 mt-2">
                <video class="w-100 h-100" controls autoPLay muted>
                  <source
                    src="/videos/catalogs/2024/1014.mp4"
                    type="video/mp4"
                  ></source>
                </video>
              </div>
            </div>
            <div className="col-sm-5 col-md-5 pl-2 pr-0">
              <div className="dbl-sub-sec h-100 mt-2">
                <a href="/decorsdetail/1014-hercley-oak" target="_blank">
                  <img
                    className=" w-100 h-100"
                    src="/images/2024/7.webp"
                  ></img>
                  <div className="decor-tag-link" style={{ display: "none" }}>
                    <div class="decor-tag">
                      <span class="dt-icon float-left">
                        <a
                          href="/decorsdetail/1014-hercley-oak"
                          target="_blank"
                        >
                          <img src="/images/magnifying-glass-plus.png"></img>
                        </a>
                      </span>
                      <span class="dt-text float-right">View Decor</span>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div className="container dbl-2 mt-3 mb-3">
          <div className="row padding-slides">
            <p className="m-0">Decor Code: 4099 City Oak</p>
            <div className="col-sm-12 col-md-7 pl-2 pr-0">
              <div className="dbl-main-sec h-100 mt-2">
                <video class="w-100 h-100" controls autoPLay muted>
                  <source
                    src="/videos/catalogs/2024/4099.mp4"
                    type="video/mp4"
                  ></source>
                </video>
              </div>
            </div>
            <div className="col-sm-5 col-md-5 pl-2 pr-0">
              <div className="dbl-sub-sec h-100 mt-2">
                <a href="/decorsdetail/4099-city-oak" target="_blank">
                  <img
                    className=" w-100 h-100"
                    src="/images/2024/8.webp"
                  ></img>
                  <div className="decor-tag-link" style={{ display: "none" }}>
                    <div class="decor-tag">
                      <span class="dt-icon float-left">
                        <a
                          href="/decorsdetail/4099-city-oak"
                          target="_blank"
                        >
                          <img src="/images/magnifying-glass-plus.png"></img>
                        </a>
                      </span>
                      <span class="dt-text float-right">View Decor</span>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div className="container dbl-2 mt-3 mb-3">
          <div className="row padding-slides">
            <p className="m-0">Decor Code: 4101 Arora Elm</p>
            <div className="col-sm-12 col-md-7 pl-2 pr-0">
              <div className="dbl-main-sec h-100 mt-2">
                <video class="w-100 h-100" controls autoPLay muted>
                  <source
                    src="/videos/catalogs/2024/4101.mp4"
                    type="video/mp4"
                  ></source>
                </video>
              </div>
            </div>
            <div className="col-sm-5 col-md-5 pl-2 pr-0">
              <div className="dbl-sub-sec h-100 mt-2">
                <a href="/decorsdetail/4101-arora-elm" target="_blank">
                  <img
                    className=" w-100 h-100"
                    src="/images/2024/9.webp"
                  ></img>
                  <div className="decor-tag-link" style={{ display: "none" }}>
                    <div class="decor-tag">
                      <span class="dt-icon float-left">
                        <a
                          href="/decorsdetail/4101-arora-elm"
                          target="_blank"
                        >
                          <img src="/images/magnifying-glass-plus.png"></img>
                        </a>
                      </span>
                      <span class="dt-text float-right">View Decor</span>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>
      
      <SwiperSlide>
        <div className="container dbl-2 mt-3 mb-3">
          <div className="row padding-slides">
            <p className="m-0">Decor Code: 1015 Hercley Oak</p>
            <div className="col-sm-12 col-md-7 pl-2 pr-0">
              <div className="dbl-main-sec h-100 mt-2">
                <video class="w-100 h-100" controls autoPLay muted>
                  <source
                    src="/videos/catalogs/2024/1015.mp4"
                    type="video/mp4"
                  ></source>
                </video>
              </div>
            </div>
            <div className="col-sm-5 col-md-5 pl-2 pr-0">
              <div className="dbl-sub-sec h-100 mt-2">
                <a href="/decorsdetail/1015-hercley-oak" target="_blank">
                  <img
                    className=" w-100 h-100"
                    src="/images/2024/10.webp"
                  ></img>
                  <div className="decor-tag-link" style={{ display: "none" }}>
                    <div class="decor-tag">
                      <span class="dt-icon float-left">
                        <a
                          href="/decorsdetail/1015-hercley-oak"
                          target="_blank"
                        >
                          <img src="/images/magnifying-glass-plus.png"></img>
                        </a>
                      </span>
                      <span class="dt-text float-right">View Decor</span>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div className="container dbl-2 mt-3 mb-3">
          <div className="row padding-slides">
            <p className="m-0">Decor Code: 4102 Arora Elm</p>
            <div className="col-sm-12 col-md-7 pl-2 pr-0">
              <div className="dbl-main-sec h-100 mt-2">
                <video class="w-100 h-100" controls autoPLay muted>
                  <source
                    src="/videos/catalogs/2024/4102.mp4"
                    type="video/mp4"
                  ></source>
                </video>
              </div>
            </div>
            <div className="col-sm-5 col-md-5 pl-2 pr-0">
              <div className="dbl-sub-sec h-100 mt-2">
                <a href="/decorsdetail/4102-arora-elm" target="_blank">
                  <img
                    className=" w-100 h-100"
                    src="/images/2024/11.webp"
                  ></img>
                  <div className="decor-tag-link" style={{ display: "none" }}>
                    <div class="decor-tag">
                      <span class="dt-icon float-left">
                        <a
                          href="/decorsdetail/4102-arora-elm"
                          target="_blank"
                        >
                          <img src="/images/magnifying-glass-plus.png"></img>
                        </a>
                      </span>
                      <span class="dt-text float-right">View Decor</span>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div className="container dbl-2 mt-3 mb-3">
          <div className="row padding-slides">
            <p className="m-0">Decor Code: 2040 Rust Ember</p>
            <div className="col-sm-12 col-md-7 pl-2 pr-0">
              <div className="dbl-main-sec h-100 mt-2">
                <video class="w-100 h-100" controls autoPLay muted>
                  <source
                    src="/videos/catalogs/2024/2040.mp4"
                    type="video/mp4"
                  ></source>
                </video>
              </div>
            </div>
            <div className="col-sm-5 col-md-5 pl-2 pr-0">
              <div className="dbl-sub-sec h-100 mt-2">
                <a href="/decorsdetail/2040-rust-ember" target="_blank">
                  <img
                    className=" w-100 h-100"
                    src="/images/2024/12.webp"
                  ></img>
                  <div className="decor-tag-link" style={{ display: "none" }}>
                    <div class="decor-tag">
                      <span class="dt-icon float-left">
                        <a
                          href="/decorsdetail/2040-rust-ember"
                          target="_blank"
                        >
                          <img src="/images/magnifying-glass-plus.png"></img>
                        </a>
                      </span>
                      <span class="dt-text float-right">View Decor</span>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div className="container dbl-2 mt-3 mb-3">
          <div className="row padding-slides">
            <p className="m-0">Decor Code: 2038 Concrete Grey</p>
            <div className="col-sm-12 col-md-7 pl-2 pr-0">
              <div className="dbl-main-sec h-100 mt-2">
                <video class="w-100 h-100" controls autoPLay muted>
                  <source
                    src="/videos/catalogs/2024/2038.mp4"
                    type="video/mp4"
                  ></source>
                </video>
              </div>
            </div>
            <div className="col-sm-5 col-md-5 pl-2 pr-0">
              <div className="dbl-sub-sec h-100 mt-2">
                <a href="/decorsdetail/2038-concrete-grey" target="_blank">
                  <img
                    className=" w-100 h-100"
                    src="/images/2024/13.webp"
                  ></img>
                  <div className="decor-tag-link" style={{ display: "none" }}>
                    <div class="decor-tag">
                      <span class="dt-icon float-left">
                        <a
                          href="/decorsdetail/2038-concrete-grey"
                          target="_blank"
                        >
                          <img src="/images/magnifying-glass-plus.png"></img>
                        </a>
                      </span>
                      <span class="dt-text float-right">View Decor</span>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div className="container dbl-2 mt-3 mb-3">
          <div className="row padding-slides">
            <p className="m-0">Decor Code: 2041 Cherry Blossom</p>
            <div className="col-sm-12 col-md-7 pl-2 pr-0">
              <div className="dbl-main-sec h-100 mt-2">
                <video class="w-100 h-100" controls autoPLay muted>
                  <source
                    src="/videos/catalogs/2024/2041.mp4"
                    type="video/mp4"
                  ></source>
                </video>
              </div>
            </div>
            <div className="col-sm-5 col-md-5 pl-2 pr-0">
              <div className="dbl-sub-sec h-100 mt-2">
                <a href="/decorsdetail/2041-cherry-blossom" target="_blank">
                  <img
                    className=" w-100 h-100"
                    src="/images/2024/14.webp"
                  ></img>
                  <div className="decor-tag-link" style={{ display: "none" }}>
                    <div class="decor-tag">
                      <span class="dt-icon float-left">
                        <a
                          href="/decorsdetail/2041-cherry-blossom"
                          target="_blank"
                        >
                          <img src="/images/magnifying-glass-plus.png"></img>
                        </a>
                      </span>
                      <span class="dt-text float-right">View Decor</span>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div className="container dbl-2 mt-3 mb-3">
          <div className="row padding-slides">
            <p className="m-0">Decor Code: 2042 Rose Purple</p>
            <div className="col-sm-12 col-md-7 pl-2 pr-0">
              <div className="dbl-main-sec h-100 mt-2">
                <video class="w-100 h-100" controls autoPLay muted>
                  <source
                    src="/videos/catalogs/2024/2042.mp4"
                    type="video/mp4"
                  ></source>
                </video>
              </div>
            </div>
            <div className="col-sm-5 col-md-5 pl-2 pr-0">
              <div className="dbl-sub-sec h-100 mt-2">
                <a href="/decorsdetail/2042-rose-purple" target="_blank">
                  <img
                    className=" w-100 h-100"
                    src="/images/2024/15.webp"
                  ></img>
                  <div className="decor-tag-link" style={{ display: "none" }}>
                    <div class="decor-tag">
                      <span class="dt-icon float-left">
                        <a
                          href="/decorsdetail/2042-rose-purple"
                          target="_blank"
                        >
                          <img src="/images/magnifying-glass-plus.png"></img>
                        </a>
                      </span>
                      <span class="dt-text float-right">View Decor</span>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div className="container dbl-2 mt-3 mb-3">
          <div className="row padding-slides">
            <p className="m-0">Decor Code: 2039 Graphite Grey</p>
            <div className="col-sm-12 col-md-7 pl-2 pr-0">
              <div className="dbl-main-sec h-100 mt-2">
                <video class="w-100 h-100" controls autoPLay muted>
                  <source
                    src="/videos/catalogs/2024/2039.mp4"
                    type="video/mp4"
                  ></source>
                </video>
              </div>
            </div>
            <div className="col-sm-5 col-md-5 pl-2 pr-0">
              <div className="dbl-sub-sec h-100 mt-2">
                <a href="/decorsdetail/2039-graphite-grey" target="_blank">
                  <img
                    className=" w-100 h-100"
                    src="/images/2024/16.webp"
                  ></img>
                  <div className="decor-tag-link" style={{ display: "none" }}>
                    <div class="decor-tag">
                      <span class="dt-icon float-left">
                        <a
                          href="/decorsdetail/2039-graphite-grey"
                          target="_blank"
                        >
                          <img src="/images/magnifying-glass-plus.png"></img>
                        </a>
                      </span>
                      <span class="dt-text float-right">View Decor</span>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div className="container dbl-2 mt-3 mb-3">
          <div className="row padding-slides">
            <p className="m-0">Decor Code: 2037 Stone Grey</p>
            <div className="col-sm-12 col-md-7 pl-2 pr-0">
              <div className="dbl-main-sec h-100 mt-2">
                <video class="w-100 h-100" controls autoPLay muted>
                  <source
                    src="/videos/catalogs/2024/2037.mp4"
                    type="video/mp4"
                  ></source>
                </video>
              </div>
            </div>
            <div className="col-sm-5 col-md-5 pl-2 pr-0">
              <div className="dbl-sub-sec h-100 mt-2">
                <a href="/decorsdetail/2037-stone-grey" target="_blank">
                  <img
                    className=" w-100 h-100"
                    src="/images/2024/17.webp"
                  ></img>
                  <div className="decor-tag-link" style={{ display: "none" }}>
                    <div class="decor-tag">
                      <span class="dt-icon float-left">
                        <a
                          href="/decorsdetail/2037-stone-grey"
                          target="_blank"
                        >
                          <img src="/images/magnifying-glass-plus.png"></img>
                        </a>
                      </span>
                      <span class="dt-text float-right">View Decor</span>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div className="container dbl-2 mt-3 mb-3">
          <div className="row padding-slides">
            <p className="m-0">Decor Code: G005 Galaxy Black</p>
            <div className="col-sm-12 col-md-7 pl-2 pr-0">
              <div className="dbl-main-sec h-100 mt-2">
                <video class="w-100 h-100" controls autoPLay muted style={{ objectFit: "cover" }}>
                  <source
                    src="/videos/catalogs/2024/G005.mp4"
                    type="video/mp4"
                  ></source>
                </video>
              </div>
            </div>
            <div className="col-sm-5 col-md-5 pl-2 pr-0">
              <div className="dbl-sub-sec h-100 mt-2">
                <a href="/scintilla" target="_blank">
                  <video class="w-100 h-100" autoPLay muted loop style={{ objectFit: "cover" }}>
                  <source
                    src="/videos/catalogs/2024/G005-decor.mp4"
                    type="video/mp4"
                  ></source>
                </video>
                  <div className="decor-tag-link" style={{ display: "none" }}>
                    <div class="decor-tag">
                      <span class="dt-icon float-left">
                        <a
                          href="/scintilla"
                          target="_blank"
                        >
                          <img src="/images/magnifying-glass-plus.png"></img>
                        </a>
                      </span>
                      <span class="dt-text float-right">View Decor</span>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div className="container dbl-2 mt-3 mb-3">
          <div className="row padding-slides">
            <p className="m-0">Decor Code: G001 White Pearl</p>
            <div className="col-sm-12 col-md-7 pl-2 pr-0">
              <div className="dbl-main-sec h-100 mt-2">
                <video class="w-100 h-100" controls autoPLay muted style={{ objectFit: "cover" }}>
                  <source
                    src="/videos/catalogs/2024/G001.mp4"
                    type="video/mp4"
                  ></source>
                </video>
              </div>
            </div>
            <div className="col-sm-5 col-md-5 pl-2 pr-0">
              <div className="dbl-sub-sec h-100 mt-2">
                <a href="/scintilla" target="_blank">
                <video class="w-100 h-100" autoPLay muted loop style={{ objectFit: "cover" }}>
                  <source
                    src="/videos/catalogs/2024/G001-decor.mp4"
                    type="video/mp4"
                  ></source>
                </video>
                  <div className="decor-tag-link" style={{ display: "none" }}>
                    <div class="decor-tag">
                      <span class="dt-icon float-left">
                        <a
                          href="/scintilla"
                          target="_blank"
                        >
                          <img src="/images/magnifying-glass-plus.png"></img>
                        </a>
                      </span>
                      <span class="dt-text float-right">View Decor</span>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div className="container dbl-2 mt-3 mb-3">
          <div className="row padding-slides">
            <p className="m-0">Decor Code: G002 Sky Grey</p>
            <div className="col-sm-12 col-md-7 pl-2 pr-0">
              <div className="dbl-main-sec h-100 mt-2">
                <video class="w-100 h-100" controls autoPLay muted style={{ objectFit: "cover" }}>
                  <source
                    src="/videos/catalogs/2024/G002.mp4"
                    type="video/mp4"
                  ></source>
                </video>
              </div>
            </div>
            <div className="col-sm-5 col-md-5 pl-2 pr-0">
              <div className="dbl-sub-sec h-100 mt-2">
                <a href="/scintilla" target="_blank">
                <video class="w-100 h-100" autoPLay muted loop style={{ objectFit: "cover" }}>
                  <source
                    src="/videos/catalogs/2024/G002-decor.mp4"
                    type="video/mp4"
                  ></source>
                </video>
                  <div className="decor-tag-link" style={{ display: "none" }}>
                    <div class="decor-tag">
                      <span class="dt-icon float-left">
                        <a
                          href="/scintilla"
                          target="_blank"
                        >
                          <img src="/images/magnifying-glass-plus.png"></img>
                        </a>
                      </span>
                      <span class="dt-text float-right">View Decor</span>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div className="container dbl-2 mt-3 mb-3">
          <div className="row padding-slides">
            <p className="m-0">Decor Code: G004 Rusty Red</p>
            <div className="col-sm-12 col-md-7 pl-2 pr-0">
              <div className="dbl-main-sec h-100 mt-2">
                <video class="w-100 h-100" controls loop autoPLay muted style={{ objectFit: "cover" }}>
                  <source
                    src="/videos/catalogs/2024/G004.mp4"
                    type="video/mp4"
                  ></source>
                </video>
              </div>
            </div>
            <div className="col-sm-5 col-md-5 pl-2 pr-0">
              <div className="dbl-sub-sec h-100 mt-2">
                <a href="/scintilla" target="_blank">
                <video class="w-100 h-100" autoPLay muted loop style={{ objectFit: "cover" }}>
                  <source
                    src="/videos/catalogs/2024/G004-decor.mp4"
                    type="video/mp4"
                  ></source>
                </video>
                  <div className="decor-tag-link" style={{ display: "none" }}>
                    <div class="decor-tag">
                      <span class="dt-icon float-left">
                        <a
                          href="/scintilla"
                          target="_blank"
                        >
                          <img src="/images/magnifying-glass-plus.png"></img>
                        </a>
                      </span>
                      <span class="dt-text float-right">View Decor</span>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div className="container dbl-2 mt-3 mb-3">
          <div className="row padding-slides">
            <p className="m-0">Decor Code: G003 Sky Monnal Grey</p>
            <div className="col-sm-12 col-md-7 pl-2 pr-0">
              <div className="dbl-main-sec h-100 mt-2">
                <video class="w-100 h-100" controls autoPLay muted loop style={{ objectFit: "cover" }}>
                  <source
                    src="/videos/catalogs/2024/G003.mp4"
                    type="video/mp4"
                  ></source>
                </video>
              </div>
            </div>
            <div className="col-sm-5 col-md-5 pl-2 pr-0">
              <div className="dbl-sub-sec h-100 mt-2">
                <a href="/scintilla" target="_blank">
                <video class="w-100 h-100" autoPLay muted loop style={{ objectFit: "cover" }}>
                  <source
                    src="/videos/catalogs/2024/G003-decor.mp4"
                    type="video/mp4"
                  ></source>
                </video>
                  <div className="decor-tag-link" style={{ display: "none" }}>
                    <div class="decor-tag">
                      <span class="dt-icon float-left">
                        <a
                          href="/scintilla"
                          target="_blank"
                        >
                          <img src="/images/magnifying-glass-plus.png"></img>
                        </a>
                      </span>
                      <span class="dt-text float-right">View Decor</span>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>

      



  

      <div className="text-center mt-3 catalog">
        <p>
          <i class="fa-solid fa-angle-left"></i>
          <i class="fa-solid fa-angle-right"></i>
        </p>
      </div>
    </Swiper>
  );
};

export default Homediaries;
