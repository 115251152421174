import React, { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";

import "swiper/css/navigation";

// import required modules
import { Navigation } from "swiper";

import $ from "jquery";

const style1 = { display: "none" };

const imageZoom = async () => {
  $(".fa-angle-left").on("click", function () {
    $(".swiper-button-prev").click();
  });

  $(".fa-angle-right").on("click", function () {
    $(".swiper-button-next").click();
  });

  $(".swiper-button-next, .swiper-button-prev").attr("style", "display:none");

  $("body").keyup(function (e) {
    var code = e.keyCode || e.which; //Find the key pressed

    if (code == 39) {
      $(".fa-angle-right").click();
    }
    if (code == 37) {
      $(".fa-angle-left").click();
    }
  });

  $(".dbl-sub-sec").on("mouseenter", function () {
    $(this).find(".decor-tag-link").fadeIn();
  });

  $(".dbl-sub-sec").on("mouseleave", function () {
    $(this).find(".decor-tag-link").fadeOut();
  });

  /*document.getElementById('produto').style.display="none";*/
};

const Homediaries = () => {
  useEffect(() => {
    imageZoom();
  }, []);

  return (
    <Swiper
      slidesPerView={1}
      spaceBetween={20}
      modules={[Navigation]}
      navigation={true}
      className="mySwiper nexthd prvhd"
    >

<SwiperSlide className="d-flex align-items-center justify-content-center mt-4">
        <center>
          <div className="container-fluid dbl-2 mt-3 mb-3">
            <div className="row">
              <div className="col-12 col-md-12 pl-2 pr-2">
                <div className="h-100">
                  <img className="mt-1 hieghtimg" src="/images/2021/1.1-3.png" />
                </div>
              </div>
            </div>
          </div>
        </center>
      </SwiperSlide>



      <SwiperSlide className="d-flex align-items-center justify-content-center mt-4">
        <div className="container dbl-2 mt-3 mb-3">
          <div className="row">
            <div className="col-12 col-md-12 pl-2 pr-2">
              <center>
                <div className="h-100">
                  <img className="w-100 h-100" src="/images/2021/2.jpg"></img>
                  <div className="decor-tag-link" style={{ display: "none" }}>
                    <div class="decor-tag">
                      <span class="dt-icon float-left">
                        <img src="/images/magnifying-glass-plus.png"></img>
                      </span>
                      <span class="dt-text float-right">View Decor</span>
                    </div>
                  </div>
                </div>
              </center>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide className="d-flex align-items-center justify-content-center mt-4">
        <div className="container dbl-2 mt-3 mb-3">
          <div className="row">
            <div className="col-12 col-md-12 pl-2 pr-2">
              <div className="h-100">
                <img className="w-100 h-100" src="/images/2021/3.jpg"></img>
                <div className="decor-tag-link" style={{ display: "none" }}>
                  <div class="decor-tag">
                    <span class="dt-icon float-left">
                      <img src="/images/magnifying-glass-plus.png"></img>
                    </span>
                    <span class="dt-text float-right">View Decor</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide className="d-flex align-items-center justify-content-center mt-4">
        <div className="container dbl-2 mt-3 mb-3">
          <div className="row">
            <div className="col-12 col-md-12 pl-2 pr-2">
              <div className="h-100">
                <img className="w-100 h-100" src="/images/2021/4.jpg"></img>
                <div className="decor-tag-link" style={{ display: "none" }}>
                  <div class="decor-tag">
                    <span class="dt-icon float-left">
                      <img src="/images/magnifying-glass-plus.png"></img>
                    </span>
                    <span class="dt-text float-right">View Decor</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide className="d-flex align-items-center justify-content-center mt-4">
        <div className="container dbl-2 mt-3 mb-3">
          <div className="row">
            <div className="col-12 col-md-12 pl-2 pr-2">
              <div className="h-100">
                <img className="w-100 h-100" src="/images/2021/5.jpg"></img>
                <div className="decor-tag-link" style={{ display: "none" }}>
                  <div class="decor-tag">
                    <span class="dt-icon float-left">
                      <img src="/images/magnifying-glass-plus.png"></img>
                    </span>
                    <span class="dt-text float-right">View Decor</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div className="container dbl-2 mt-3 mb-3">
          <div className="row">
            <p className="m-0">Decor Code: 1201</p>
            <div className="col-sm-12 col-md-7 pl-2 pr-0">
              <div className="dbl-main-sec h-100 mt-2">
                <video class="w-100 h-100" controls autoplay>
                  <source
                    src="/videos/catalogs/2021/1.mp4"
                    type="video/mp4"
                  ></source>
                </video>
              </div>
            </div>
            <div className="col-sm-5 col-md-5 pl-2 pr-0">
              <div className="dbl-sub-sec h-100 mt-2">
                <a href="/decorsdetail/1201" target="_blank">
                  <img
                    className=" w-100 h-100"
                    src="/images/2021/1201/1.jpg"
                  ></img>
                  <div className="decor-tag-link" style={{ display: "none" }}>
                    <div class="decor-tag">
                      <span class="dt-icon float-left">
                      <a href="/decorsdetail/1201" target="_blank">
                        <img src="/images/magnifying-glass-plus.png"></img>
                        </a>
                      </span>
                      <span class="dt-text float-right">View Decor</span>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div className="container dbl-2 mt-3 mb-3">
          <div className="row">
            <p className="m-0">Decor Code: 1202</p>
            <div className="col-sm-12 col-md-7 pl-2 pr-0">
              <div className="dbl-main-sec h-100 mt-2">
                <a href="/decorsdetail/1202" target="_blank">
                  <img
                    className="lb-btn mt-1 mb-2 w-100 h-100"
                    src="/images/2021/1202/2.png"
                  ></img>
                  <div className="decor-tag-link" style={{ display: "none" }}>
                    <div class="decor-tag">
                      <span class="dt-icon float-left">
                      <a href="/decorsdetail/1202" target="_blank">
                        <img src="/images/magnifying-glass-plus.png"></img></a>
                      </span>
                      <span class="dt-text float-right">View Decor</span>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-sm-5 col-md-5 pl-2 pr-0">
              <div className="dbl-sub-sec h-100 mt-2">
                <a href="/decorsdetail/1202" target="_blank">
                  <img
                    className="mt-1 w-100 h-100"
                    src="/images/2021/1202/1.png"
                  ></img>
                  <div className="decor-tag-link" style={{ display: "none" }}>
                    <div class="decor-tag">
                      <span class="dt-icon float-left">
                      <a href="/decorsdetail/1202" target="_blank">
                        <img src="/images/magnifying-glass-plus.png"></img></a>
                      </span>
                      <span class="dt-text float-right">View Decor</span>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div className="container dbl-1 d-flex align-items-center justify-content-center mt-4">
          <div className="row">
            <p className="m-0">Decor Code: 1203</p>
            <div className="col-md-8 pl-1 pr-1 mt-2 mb-1 dbl-main-sec">
              <img
                className="lb-btn mt-1 mb-1 w-100 h-100"
                src="images/2021/1203/2.png"
              ></img>
            </div>

            <div className="col-md-4 mt-1 mb-1 pl-2 pr-2">
              <div className="row">
                <div className="col-6 col-md-12 pl-2 pr-2 mt-2 dbl-sub-sec">
                  <div className="dbl-sub-sec">
                    <a href="/decorsdetail/1203" target="_blank">
                      <img
                        className="w-100 h-100"
                        src="images/2021/1203/1.png"
                      ></img>
                      <div
                        className="decor-tag-link"
                        style={{ display: "none" }}
                      >
                        <div class="decor-tag">
                          <span class="dt-icon float-left">
                            <img src="/images/magnifying-glass-plus.png"></img>
                          </span>
                          <span class="dt-text float-right">View Decor</span>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>

                <div className="col-6 col-md-12 pl-2 pr-2 mt-2 dbl-sub-sec">
                  <div className="dbl-sub-sec">
                    <a href="/decorsdetail/1203" target="_blank">
                      <img
                        className="w-100 h-100"
                        src="images/2021/1203/1.jpg"
                      ></img>
                      <div
                        className="decor-tag-link"
                        style={{ display: "none" }}
                      >
                        <div class="decor-tag">
                          <span class="dt-icon float-left">
                            <img src="/images/magnifying-glass-plus.png"></img>
                          </span>
                          <span class="dt-text float-right">View Decor</span>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div className="container dbl-2 mt-3 mb-3">
          <div className="row">
            <p className="m-0">Decor Code: 1204</p>
            <div className="col-sm-12 col-md-7 pl-2 pr-0">
              <div className="dbl-main-sec h-100 mt-2">
                <img
                  className="lb-btn mt-1 mb-2 w-100 h-100"
                  src="/images/2021/1204/2.jpg"
                />
              </div>
            </div>
            <div className="col-sm-5 col-md-5 pl-2 pr-0">
              <div className="dbl-sub-sec h-100 mt-2">
                <a href="/decorsdetail/1204" target="_blank">
                  <img
                    className="mt-1 w-100 h-100"
                    src="/images/2021/1204/1.jpg"
                  ></img>
                  <div className="decor-tag-link" style={{ display: "none" }}>
                    <div class="decor-tag">
                      <span class="dt-icon float-left">
                        <img src="/images/magnifying-glass-plus.png"></img>
                      </span>
                      <span class="dt-text float-right">View Decor</span>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div className="container dbl-2 mt-3 mb-3">
          <div className="row">
            <p className="m-0">Decor Code: 1205</p>
            <div className="col-sm-12 col-md-7 pl-2 pr-0">
              <div className="dbl-main-sec h-100 mt-2">
                <img
                  className="lb-btn mt-1 mb-2 w-100 h-100"
                  src="/images/2021/1205/2.jpg"
                />
              </div>
            </div>
            <div className="col-sm-5 col-md-5 pl-2 pr-0">
              <div className="dbl-sub-sec h-100 mt-2">
                <a href="/decorsdetail/1205" target="_blank">
                  <img
                    className="mt-1 w-100 h-100"
                    src="/images/2021/1205/1.jpg"
                  ></img>
                  <div className="decor-tag-link" style={{ display: "none" }}>
                    <div class="decor-tag">
                      <span class="dt-icon float-left">
                        <img src="/images/magnifying-glass-plus.png"></img>
                      </span>
                      <span class="dt-text float-right">View Decor</span>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div className="container dbl-1 d-flex align-items-center justify-content-center mt-4">
          <div className="row">
            <p className="m-0">Decor Code: 1206</p>
            <div className="col-md-8 pl-1 pr-1 mt-2 mb-1 dbl-main-sec">
              <video class="w-100 h-100" controls autoplay>
                <source
                  src="/videos/catalogs/2021/2.mp4"
                  type="video/mp4"
                ></source>
              </video>
            </div>

            <div className="col-md-4 mt-1 mb-1 pl-2 pr-2">
              <div className="row">
                <div className="col-6 col-md-12 pl-2 pr-2 mt-2 dbl-sub-sec">
                  <div className="dbl-sub-sec">
                    <a href="/decorsdetail/1206" target="_blank">
                      <img
                        className="w-100 h-100"
                        src="/images/2021/1206/2.jpg"
                      ></img>
                      <div
                        className="decor-tag-link"
                        style={{ display: "none" }}
                      >
                        <div class="decor-tag">
                          <span class="dt-icon float-left">
                            <img src="/images/magnifying-glass-plus.png"></img>
                          </span>
                          <span class="dt-text float-right">View Decor</span>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>

                <div className="col-6 col-md-12 pl-2 pr-2 mt-2 dbl-sub-sec">
                  <div className="dbl-sub-sec">
                    <a href="/decorsdetail/1206" target="_blank">
                      <img
                        className="w-100 h-100"
                        src="/images/2021/1206/1.jpg"
                      ></img>
                      <div
                        className="decor-tag-link"
                        style={{ display: "none" }}
                      >
                        <div class="decor-tag">
                          <span class="dt-icon float-left">
                            <img src="/images/magnifying-glass-plus.png"></img>
                          </span>
                          <span class="dt-text float-right">View Decor</span>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div className="container dbl-2 mt-3 mb-3">
          <div className="row">
            <p className="m-0">Decor Code: S011</p>
            <div className="col-sm-12 col-md-7 pl-2 pr-0">
              <div className="dbl-main-sec h-100 mt-2">
                <video class="w-100 h-100" controls autoplay>
                  <source
                    src="/videos/catalogs/2021/3.mp4"
                    type="video/mp4"
                  ></source>
                </video>
              </div>
            </div>
            <div className="col-sm-5 col-md-5 pl-2 pr-0">
              <div className="dbl-sub-sec h-100 mt-2">
                <a href="/decorsdetail/s011-pure" target="_blank">
                  <img
                    className=" w-100 h-100"
                    src="/images/2021/S011/1.jpg"
                  ></img>
                  <div className="decor-tag-link" style={{ display: "none" }}>
                    <div class="decor-tag">
                      <span class="dt-icon float-left">
                        <img src="/images/magnifying-glass-plus.png"></img>
                      </span>
                      <span class="dt-text float-right">View Decor</span>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div className="container dbl-1 d-flex align-items-center justify-content-center mt-4">
          <div className="row">
            <p className="m-0">Decor Code: S012</p>
            <div className="col-md-8 pl-1 pr-1 mt-2 mb-1 dbl-main-sec">
            <a href="/decorsdetail/s012-nero-marquina" target="_blank">
              <img
                className="lb-btn mt-1 mb-1 w-100 h-100"
                src="/images/2021/S021/3.jpg"
              ></img></a>
            </div>

            <div className="col-md-4 mt-1 mb-1 pl-2 pr-2">
              <div className="row">
                <div className="col-6 col-md-12 pl-2 pr-2 mt-2 dbl-sub-sec">
                  <div className="dbl-sub-sec">
                    <a href="/decorsdetail/s012-nero-marquina" target="_blank">
                      <img
                        className="w-100 h-100"
                        src="/images/2021/S021/2.jpg"
                      ></img>
                      <div
                        className="decor-tag-link"
                        style={{ display: "none" }}
                      >
                        <div class="decor-tag">
                          <span class="dt-icon float-left">
                            <img src="/images/magnifying-glass-plus.png"></img>
                          </span>
                          <span class="dt-text float-right">View Decor</span>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>

                <div className="col-6 col-md-12 pl-2 pr-2 mt-2 dbl-sub-sec">
                  <div className="dbl-sub-sec">
                    <img
                      className="w-100 h-100"
                      src="/images/2021/S021/1.jpg"
                    ></img>
                    <div className="decor-tag-link" style={{ display: "none" }}>
                      <div class="decor-tag">
                        <span class="dt-icon float-left">
                          <img src="/images/magnifying-glass-plus.png"></img>
                        </span>
                        <span class="dt-text float-right">View Decor</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div className="container dbl-1 d-flex align-items-center justify-content-center mt-4">
          <div className="row">
            <p className="m-0">Decor Code: 1010</p>
            <div className="col-md-8 pl-1 pr-1 mt-2 mb-1 dbl-main-sec">
          <a href="/decorsdetail/1010" target="_blank">
              <img
                className="lb-btn mt-1 mb-1 w-100 h-100"
                src="/images/2021/1010/3.jpg"
              ></img>
              <div className="decor-tag-link" style={{ display: "none" }}>
                <div class="decor-tag">
                  <span class="dt-icon float-left">
                    <img src="/images/magnifying-glass-plus.png"></img>
                  </span>
                  <span class="dt-text float-right">View Decor</span>
                </div>
              </div></a>
            </div>

            <div className="col-md-4 mt-1 mb-1 pl-2 pr-2">
              <div className="row">
                <div className="col-6 col-md-12 pl-2 pr-2 mt-2 dbl-sub-sec">
                  <div className="dbl-sub-sec">
                  <a href="/decorsdetail/1010" target="_blank">
                    <img
                      className="w-100 h-100"
                      src="/images/2021/1010/2.jpg"
                    ></img>
                    <div className="decor-tag-link" style={{ display: "none" }}>
                      <div class="decor-tag">
                        <span class="dt-icon float-left">
                          <img src="/images/magnifying-glass-plus.png"></img>
                        </span>
                        <span class="dt-text float-right">View Decor</span>
                      </div>
                    </div></a>
                  </div>
                </div>

                <div className="col-6 col-md-12 pl-2 pr-2 mt-2 dbl-sub-sec">
                  <div className="dbl-sub-sec">
                  <a href="/decorsdetail/1010" target="_blank">
                    <img
                      className="w-100 h-100"
                      src="/images/2021/1010/1.jpg"
                    ></img>
                    <div className="decor-tag-link" style={{ display: "none" }}>
                      <div class="decor-tag">
                        <span class="dt-icon float-left">
                          <img src="/images/magnifying-glass-plus.png"></img>
                        </span>
                        <span class="dt-text float-right">View Decor</span>
                      </div>
                    </div></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div className="container dbl-2 mt-3 mb-3">
          <div className="row">
            <p className="m-0">Decor Code: 1011</p>
            <div className="col-sm-12 col-md-7 pl-2 pr-0">
              <div className="dbl-main-sec h-100 mt-2">
                <video class="w-100 h-100" controls autoplay>
                  <source
                    src="/videos/catalogs/2021/4.mp4"
                    type="video/mp4"
                  ></source>
                </video>
              </div>
            </div>
            <div className="col-sm-5 col-md-5 pl-2 pr-0">
              <div className="dbl-sub-sec h-100 mt-2">
              <a href="/decorsdetail/1011" target="_blank">
                <img
                  className=" w-100 h-100"
                  src="/images/2021/1011/1.jpg"
                ></img>
                <div className="decor-tag-link" style={{ display: "none" }}>
                  <div class="decor-tag">
                    <span class="dt-icon float-left">
                      <img src="/images/magnifying-glass-plus.png"></img>
                    </span>
                    <span class="dt-text float-right">View Decor</span>
                  </div>
                </div></a>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div className="container dbl-2 mt-3 mb-3">
          <div className="row">
            <p className="m-0">Decor Code: 1012</p>
            <div className="col-sm-12 col-md-7 pl-2 pr-0">
              <div className="dbl-main-sec h-100 mt-2">
                <video class="w-100 h-100" controls autoplay>
                  <source
                    src="/videos/catalogs/2021/5.mp4"
                    type="video/mp4"
                  ></source>
                </video>
              </div>
            </div>
            <div className="col-sm-5 col-md-5 pl-2 pr-0">
              <div className="dbl-sub-sec h-100 mt-2">
              <a href="/decorsdetail/1012" target="_blank">
                <img
                  className=" w-100 h-100"
                  src="/images/2021/1012/1.jpg"
                ></img>
                <div className="decor-tag-link" style={{ display: "none" }}>
                  <div class="decor-tag">
                    <span class="dt-icon float-left">
                      <img src="/images/magnifying-glass-plus.png"></img>
                    </span>
                    <span class="dt-text float-right">View Decor</span>
                  </div>
                </div></a>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div className="container dbl-1 d-flex align-items-center justify-content-center mt-4">
          <div className="row">
            <p className="m-0">Decor Code: 1013</p>
            <div className="col-md-8 pl-1 pr-1 mt-2 mb-1 dbl-main-sec">
            <a href="/decorsdetail/1013" target="_blank">
              <img
                className="lb-btn mt-1 mb-1 w-100 h-100"
                src="/images/2021/1013/3.jpg"
              ></img>
              <div className="decor-tag-link" style={{ display: "none" }}>
                <div class="decor-tag">
                  <span class="dt-icon float-left">
                    <img src="/images/magnifying-glass-plus.png"></img>
                  </span>
                  <span class="dt-text float-right">View Decor</span>
                </div>
              </div></a>
            </div>

            <div className="col-md-4 mt-1 mb-1 pl-2 pr-2">
              <div className="row">
                <div className="col-6 col-md-12 pl-2 pr-2 mt-2 dbl-sub-sec">
                  <div className="dbl-sub-sec">
                  <a href="/decorsdetail/1013" target="_blank">
                    <img
                      className="w-100 h-100"
                      src="/images/2021/1013/2.jpg"
                    ></img>
                    <div className="decor-tag-link" style={{ display: "none" }}>
                      <div class="decor-tag">
                        <span class="dt-icon float-left">
                          <img src="/images/magnifying-glass-plus.png"></img>
                        </span>
                        <span class="dt-text float-right">View Decor</span>
                      </div>
                    </div></a>
                  </div>
                </div>

                <div className="col-6 col-md-12 pl-2 pr-2 mt-2 dbl-sub-sec">
                  <div className="dbl-sub-sec">
                  <a href="/decorsdetail/1013" target="_blank">
                    <img
                      className="w-100 h-100"
                      src="/images/2021/1013/1.jpg"
                    ></img>
                    <div className="decor-tag-link" style={{ display: "none" }}>
                      <div class="decor-tag">
                        <span class="dt-icon float-left">
                          <img src="/images/magnifying-glass-plus.png"></img>
                        </span>
                        <span class="dt-text float-right">View Decor</span>
                      </div>
                    </div></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div className="container dbl-2 mt-3 mb-3">
          <div className="row">
            <p className="m-0">Decor Code: 4091</p>
            <div className="col-sm-12 col-md-7 pl-2 pr-0">
              <div className="dbl-main-sec h-100 mt-2">
                <video class="w-100 h-100" controls autoplay>
                  <source
                    src="/videos/catalogs/2021/6.mp4"
                    type="video/mp4"
                  ></source>
                </video>
              </div>
            </div>
            <div className="col-sm-5 col-md-5 pl-2 pr-0">
              <div className="dbl-sub-sec h-100 mt-2">
              <a href="/decorsdetail/4091" target="_blank">
                <img
                  className=" w-100 h-100"
                  src="/images/2021/4091/1.jpg"
                ></img>
                <div className="decor-tag-link" style={{ display: "none" }}>
                  <div class="decor-tag">
                    <span class="dt-icon float-left">
                      <img src="/images/magnifying-glass-plus.png"></img>
                    </span>
                    <span class="dt-text float-right">View Decor</span>
                  </div>
                </div></a>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div className="container dbl-2 mt-3 mb-3">
          <div className="row">
            <p className="m-0">Decor Code: 4092</p>
            <div className="col-sm-12 col-md-7 pl-2 pr-0">
              <div className="dbl-main-sec h-100 mt-2">
              <a href="/decorsdetail/4092" target="_blank">
                <img
                  className="lb-btn mt-1 mb-2 w-100 h-100"
                  src="/images/2021/4092/2.jpg"
                /></a>
              </div>
            </div>
            <div className="col-sm-5 col-md-5 pl-2 pr-0">
              <div className="dbl-sub-sec h-100 mt-2">
              <a href="/decorsdetail/4092" target="_blank">
                <img
                  className="mt-1 w-100 h-100"
                  src="/images/2021/4092/1.jpg"
                ></img>
                <div className="decor-tag-link" style={{ display: "none" }}>
                  <div class="decor-tag">
                    <span class="dt-icon float-left">
                      <img src="/images/magnifying-glass-plus.png"></img>
                    </span>
                    <span class="dt-text float-right">View Decor</span>
                  </div>
                </div></a>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div className="container dbl-2 mt-3 mb-3">
          <div className="row">
            <p className="m-0">Decor Code: 4093</p>
            <div className="col-sm-12 col-md-7 pl-2 pr-0">
              <div className="dbl-main-sec h-100 mt-2">
              <a href="/decorsdetail/4093" target="_blank">
                <img
                  className="lb-btn mt-1 mb-2 w-100 h-100"
                  src="/images/2021/4093/2.jpg"
                /></a>
              </div>
            </div>
            <div className="col-sm-5 col-md-5 pl-2 pr-0">
              <div className="dbl-sub-sec h-100 mt-2">
              <a href="/decorsdetail/4093" target="_blank">
                <img
                  className="mt-1 w-100 h-100"
                  src="/images/2021/4093/1.jpg"
                ></img>
                <div className="decor-tag-link" style={{ display: "none" }}>
                  <div class="decor-tag">
                    <span class="dt-icon float-left">
                      <img src="/images/magnifying-glass-plus.png"></img>
                    </span>
                    <span class="dt-text float-right">View Decor</span>
                  </div>
                </div></a>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div className="container dbl-1 d-flex align-items-center justify-content-center mt-4">
          <div className="row">
            <p className="m-0">Decor Code: 4094</p>
            <div className="col-md-8 pl-1 pr-1 mt-2 mb-1 dbl-main-sec">
              <video class="w-100 h-100" controls autoplay>
                <source
                  src="/videos/catalogs/2021/7.mp4"
                  type="video/mp4"
                ></source>
              </video>
              {/* <img
                className="lb-btn mt-1 mb-1 w-100 h-100"
                src="https://res.cloudinary.com/active-bridge/image/upload/slide1.jpg"
              ></img><div className="decor-tag-link" style={{display: "none"}}>
                  <div class="decor-tag">
                    <span class="dt-icon float-left"><img src="/images/magnifying-glass-plus.png"></img></span>
                    <span class="dt-text float-right">View Decor</span>
                  </div>
                </div> */}
            </div>

            <div className="col-md-4 mt-1 mb-1 pl-2 pr-2">
              <div className="row">
                <div className="col-6 col-md-12 pl-2 pr-2 mt-2 dbl-sub-sec">
                  <div className="dbl-sub-sec">
                  <a href="/decorsdetail/4094" target="_blank">
                    <img
                      className="w-100 h-100"
                      src="/images/2021/4094/2.jpg"
                    ></img>
                    <div className="decor-tag-link" style={{ display: "none" }}>
                      <div class="decor-tag">
                        <span class="dt-icon float-left">
                          <img src="/images/magnifying-glass-plus.png"></img>
                        </span>
                        <span class="dt-text float-right">View Decor</span>
                      </div>
                    </div></a>
                  </div>
                </div>

                <div className="col-6 col-md-12 pl-2 pr-2 mt-2 dbl-sub-sec">
                  <div className="dbl-sub-sec">
                  <a href="/decorsdetail/4094" target="_blank">
                    <img
                      className="w-100 h-100"
                      src="/images/2021/4094/1.jpg"
                    ></img>
                    <div className="decor-tag-link" style={{ display: "none" }}>
                      <div class="decor-tag">
                        <span class="dt-icon float-left">
                          <img src="/images/magnifying-glass-plus.png"></img>
                        </span>
                        <span class="dt-text float-right">View Decor</span>
                      </div>
                    </div></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div className="container dbl-2 mt-3 mb-3">
          <div className="row">
            <p className="m-0">Decor Code: 4095</p>
            <div className="col-sm-12 col-md-7 pl-2 pr-0">
              <div className="dbl-main-sec h-100 mt-2">
              <a href="/decorsdetail/4095" target="_blank">
                <img
                  className="lb-btn mt-1 mb-2 w-100 h-100"
                  src="/images/2021/4095/2.jpg"
                /></a>
              </div>
            </div>
            <div className="col-sm-5 col-md-5 pl-2 pr-0">
              <div className="dbl-sub-sec h-100 mt-2">
              <a href="/decorsdetail/4095" target="_blank">
                <img
                  className="mt-1 w-100 h-100"
                  src="/images/2021/4095/1.jpg"
                ></img>
                <div className="decor-tag-link" style={{ display: "none" }}>
                  <div class="decor-tag">
                    <span class="dt-icon float-left">
                      <img src="/images/magnifying-glass-plus.png"></img>
                    </span>
                    <span class="dt-text float-right">View Decor</span>
                  </div>
                </div></a>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div className="container dbl-2 mt-3 mb-3">
          <div className="row">
            <p className="m-0">Decor Code: 2004</p>
            <div className="col-sm-12 col-md-7 pl-2 pr-0">
              <div className="dbl-main-sec h-100 mt-2">
              <a href="/decorsdetail/2004-natural-white" target="_blank">
                <img
                  className="lb-btn mt-1 mb-2 w-100 h-100"
                  src="/images/2021/2004/2.jpg"
                /></a>
              </div>
            </div>
            <div className="col-sm-5 col-md-5 pl-2 pr-0">
              <div className="dbl-sub-sec h-100 mt-2">
              <a href="/decorsdetail/2004-natural-white" target="_blank">
                <img
                  className="mt-1 w-100 h-100"
                  src="/images/2021/2004/1.jpg"
                ></img>
                <div className="decor-tag-link" style={{ display: "none" }}>
                  <div class="decor-tag">
                    <span class="dt-icon float-left">
                      <img src="/images/magnifying-glass-plus.png"></img>
                    </span>
                    <span class="dt-text float-right">View Decor</span>
                  </div>
                </div></a>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div className="container dbl-2 mt-3 mb-3">
          <div className="row">
            <p className="m-0">Decor Code: 2034</p>
            <div className="col-sm-12 col-md-7 pl-2 pr-0">
              <div className="dbl-main-sec h-100 mt-2">
                <a href="/decorsdetail/2034-teal" target="_blank">
                <img
                  className="lb-btn mt-1 mb-2 w-100 h-100"
                  src="/images/2021/2035/2.jpg"
                /></a>
              </div>
            </div>
            <div className="col-sm-5 col-md-5 pl-2 pr-0">
              <div className="dbl-sub-sec h-100 mt-2">
              <a href="/decorsdetail/2034-teal" target="_blank">
                <img
                  className="mt-1 w-100 h-100"
                  src="/images/2021/2034/1.jpg"
                ></img>
                <div className="decor-tag-link" style={{ display: "none" }}>
                  <div class="decor-tag">
                    <span class="dt-icon float-left">
                      <img src="/images/magnifying-glass-plus.png"></img>
                    </span>
                    <span class="dt-text float-right">View Decor</span>
                  </div>
                </div></a>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div className="container dbl-2 mt-3 mb-3">
          <div className="row">
            <p className="m-0">Decor Code: 2035</p>
            <div className="col-sm-12 col-md-7 pl-2 pr-0">
              <div className="dbl-main-sec h-100 mt-2">
              <video class="w-100 h-100" controls autoplay>
                  <source
                    src="/videos/catalogs/2021/8.mp4"
                    type="video/mp4"
                  ></source>
                </video>
              </div>
            </div>
            <div className="col-sm-5 col-md-5 pl-2 pr-0">
              <div className="dbl-sub-sec h-100 mt-2">
                <img
                  className=" w-100 h-100"
                  src="/images/2021/2035/1.jpg"
                ></img>
                <div className="decor-tag-link" style={{ display: "none" }}>
                  <div class="decor-tag">
                    <span class="dt-icon float-left">
                      <img src="/images/magnifying-glass-plus.png"></img>
                    </span>
                    <span class="dt-text float-right">View Decor</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <div className="text-center mt-3 catalog">
        <p>
          <i class="fa-solid fa-angle-left"></i>
          <i class="fa-solid fa-angle-right"></i>
        </p>
      </div>
    </Swiper>
  );
};

export default Homediaries;
